<template>
  <Teleport to="body">
    <Transition name="toast">
      <div
        v-if="toastStore.toasts.length"
        class="right-0 shadow-md toaster__wrapper font-roboto rounded-alt-md md:right-sm top-2xl shadow-border-light"
      >
        <div
          v-for="toast in toastStore.toasts"
          :key="toast.text"
          :class="toastClassMap[toast.status]"
          class="rounded-alt-md"
        >
          <TransitionGroup name="toast" tag="ul">
            <li class="toaster__inner mx-md my-sm">
              <span class="toaster__inner-text">
                {{ t(toast.text) }}
              </span>
            </li>
          </TransitionGroup>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useToastStore, type TToastStatus } from '@/stores/useToastStore';

const toastClassMap: Record<TToastStatus, string> = {
  warning: 'bg-status-warn-lighter',
  error: 'bg-status-danger-lighter',
  success: 'bg-status-success-lighter',
};

const toastStore = useToastStore();
const { t } = useTrans();
</script>

<style scoped lang="postcss">
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
}

.toast-enter-active,
.toast-leave-active {
  transition: 0.25s ease all;
}
.toaster {
  &__wrapper {
    position: fixed;

    z-index: 5000;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__inner {
    display: flex;
  }
}
</style>
